import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import qs from "query-string";
import { Translate, withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";

import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validateAddress,
  validatePhoneNumber,
  validateMkPhoneNumber,
  validateCompany,
  validateTaxNumber,
  validateCountry,
  validateCity,
  validateZipCode, validateCheckbox,
  normalizeMacedonianPhoneNumber
} from "../../../../common/validationRules";

import { getCountriesFormatted } from "../../../Addressing/reducers/addressingReducer";

import Breadcrumb from "../../../../components/Breadcrumb";
import BreadcrumbItem from "../../../../components/Breadcrumb/BreadcrumbItem";
import Container from "../../../../components/Containers/Container";
import AdaptableButtonGroup from "../../../../components/AdaptableButtonGroup";

import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import PrimaryWarningButton from "../../../../components/Buttons/PrimaryWarningButton";

import NoticeDanger from "../../../../components/Notices/NoticeDanger";

import Modal from "../../../../components/Modal";
import ActionModal from "../../../../components/ActionModal";

import ContentLoader from "../../../../components/Loaders/ContentLoader";

import ProInvoicePaymentDetails from "./ProInvoicePaymentDetails";
import ItemsTable from "../../../../components/ItemsTable";
import TransactionsTable from "../../../../components/TransactionsTable";
import InvoiceTotal from "../../../../components/InvoiceTotal";
import Payment from "../../Payment";
import InvoiceTo from "./InvoiceTo";
import PayTo from "./PayTo";
import ForwardDialog from "../../../../components/ForwardDialog";

import PayerDetailsForm from "./PayerDetailsForm";
import PayerDetailsFormWithSearch from "./PayerDetailsFormWithSearch";

import {
  fetchProInvoice,
  downloadProInvoice,
  applyCreditToProInvoice,
  forwardProInvoice,
  payProInvoice,
  updateProInvoicePayment,
  assignProInvoicePayer,
  cancelProInvoice
} from "../actions/proInvoicesActions";

import { fetchPaymentMethods } from "../../../PaymentMethods/actions/paymentMethodsActions";
import { getPaymentMethods } from "../../../PaymentMethods/reducers/paymentMethodsReducer";

import { searchPayers } from "../../../Customer/actions/customerActions";

import {
  fetchCredit,
  reduceCredit
} from "../../../Credit/actions/creditActions";
import {
  getAvailableCredit,
  isFetchingCredit
} from "../../../Credit/reducers/creditReducer";

import { validateRangeAmount } from "../../../../common/validationRules";

import { downloadFile } from "../../../../utils/downloadFile";

import { PAYMENT_METHOD_BANK_TRANSFER } from "../../../PaymentMethods/PaymentMethodsModules";

import { PAYMENT_STATUS_UNPAID } from "../../../PaymentMethods/PaymentStatus";

import {
  PAYER_DETAILS_TYPE_COMPANY,
  PAYER_DETAILS_TYPE_INDIVIDUAL
} from "./PayerDetailsForm/PayerDetailsTypes";

import { PROINVOICE_TYPE_CREDIT_PURCHASE } from "../ProInvoiceTypes/ProInvoiceTypes";

import { ROUTE_INDEX, ROUTE_BILLING } from "../../../../routes/routes";

import displayToastMessageForResponse from "../../../../utils/displayToastMessageForResponse";

const StyledViewProInvoice = styled.div`
  padding: ${props => (props.isMobile ? "15px" : "30px")};
`;

const StyledViewProInvoiceWrapper = styled.div`
  margin-bottom: 30px;

  & {
    @media print {
      width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      flex: 0 0 100%;
    }

    & .mainDivButtons button {
      @media (max-width: 360px) {
        padding: 10px;
      }
    }
  }
`;

const StyledProInviceName = styled.h3`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  margin-bottom: 0;

  @media (max-width: 1440px) {
    font-size: 23px;
  }
`;

const StyledProInvoiceItemsWrapper = styled.div``;
const StyledInvoiceToWrapper = styled.div`
  padding: 15px;
`;

const StyledPayNowCallToAction = styled(PrimaryButton)`
  width: ${props => props.isMobile && "100%"};
  margin-top: ${props => props.isMobile && "15px"};
  margin-bottom: ${props => props.isMobile && "15px"};
  margin-right: 0;
`;

class ViewProInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      proinvoice: null,
      downloading: false,
      showForwardModal: false,
      submittingForwardProInvoice: false,
      submittingProInvoicePayment: false,
      paymentGateways: null,
      paymentMethods: null,
      processPaymentGateway: null,
      selectedPayment: null,
      paymentFailed: false,
      showUpdatePayerModal: false,
      selectedPayer: null,
      invalidPayer: false,
      showCreatePayerModal: false,
      showChangePayerModal: false,
      applyCreditSubmitting: false,
      highlightPaymentMethods: false,
      highlightPaymentMethodsTimeout: null,
      canceling: false,
      showCancelProInvoiceModal: false
    };

    this.paymentMethodsRef = null;
  }

  componentDidMount() {
    this.doFetchProInvoice(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.doFetchProInvoice(this.props.match.params.id);
    }
  }

  doFetchProInvoice = id => {
    this.setState({ proinvoice: null });
    this.props.fetchProInvoice(id).then(result => {
      let paymentFailed = false;
      const bankRedirectStatus = qs.parse(this.props.location.search);
      if (
        result.proinvoice.status.code === PAYMENT_STATUS_UNPAID &&
        bankRedirectStatus.payment === "failed"
      ) {
        paymentFailed = true;
      }
      console.log(result.proinvoice)

      this.setState({
        proinvoice: result.proinvoice,
        processPaymentGateway: result.payment_gateway,
        paymentMethods: result.payment_methods,
        selectedPayment: result.proinvoice.payment_method.module,
        paymentFailed
      });
    });
  };

  onDownload = id => {
    this.setState({
      downloading: true
    });
    this.props.downloadProInvoice(id).then(response => {
      this.setState({
        downloading: false
      });

      if (response.error) {
        displayToastMessageForResponse(
          this.props.translate("pro-invoices.title"),
          {
            error: true,
            messages: [
              this.props.translate("pro-invoices.download-pro-invoice-error")
            ]
          }
        );

        return;
      }

      downloadFile(
        response.file.data,
        `MKHOST-PROINVOICE-${id}.pdf`,
        "application/pdf"
      );
    });
  };

  onPrint = () => {
    window.print();
  };

  validateApplyCredit = (values, maxCredit) => {
    return {
      credit: validateRangeAmount(values.credit, 1, this.props.credit.amount)
    };
  };
  onApplyCreditSubmit = values => {
    this.setState({
      applyCreditSubmitting: true
    });
    return this.props
      .applyCreditToProInvoice(this.state.proinvoice, values.credit)
      .then(({ invoice, messages, error }) => {
        this.setState({ applyCreditSubmitting: false });

        displayToastMessageForResponse(
          this.props.translate("pro-invoices.pro-invoice"),
          { error, messages }
        );

        if (!error && invoice) {
          this.setState({ proinvoice: invoice });
          this.props.reduceCredit(values.credit);

          return;
        }
      });
  };

  /**
   * Forwaring ProInvoice
   */

  onValidateForwardProInvoice = values => {
    return {
      email: validateEmail(values.email)
    };
  };

  onSubmitForwardProInvoice = () => {
    document
      .getElementById("forward-dialog-form")
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  onSubmittingForwardProInvoice = ({ submitting }) => {
    if (this.state.submittingForwardProInvoice !== submitting) {
      this.setState({
        submittingForwardProInvoice: submitting
      });
    }
  };

  onForwardProInvoice = values => {
    return this.props
      .forwardProInvoice(this.state.proinvoice, values.email, values.message)
      .then(response => {
        displayToastMessageForResponse(
          this.props.translate("pro-invoices.title"),
          response
        );
        this.onHideForwardProInvoiceModal();
      });
  };

  onShowForwardProInvoiceModal = () => {
    this.setState({
      showForwardModal: true
    });
  };

  onHideForwardProInvoiceModal = () => {
    this.setState({
      showForwardModal: false
    });
  };

  onSelectPayment = module => {
    this.setState({
      selectedPayment: module
    });
    if (module === PAYMENT_METHOD_BANK_TRANSFER) {
      this.props
        .updateProInvoicePayment(this.state.proinvoice, module)
        .then(
          result => {
            if (!result.hasOwnProperty("error")) {
              this.setState({
                proinvoice: result.invoice,
                processPaymentGateway: result.payment_gateway
              });
            } else {
              displayToastMessageForResponse(
                this.props.translate("pro-invoices.title"),
                result
              );
            }
          },
          error => {
            displayToastMessageForResponse(
              this.props.translate("pro-invoices.title"),
              error
            );
          }
        )
        .catch(error => {
          displayToastMessageForResponse(
            this.props.translate("pro-invoices.title"),
            error
          );
        });
    }
  };

  onPayProInvoice = e => {
    e.preventDefault();
    this.setState({ submittingProInvoicePayment: true });
    this.props
      .updateProInvoicePayment(
        this.state.proinvoice,
        this.state.selectedPayment
      )
      .then(
        result => {
          if (!result.hasOwnProperty("error")) {
            this.setState(
              {
                proinvoice: result.invoice,
                processPaymentGateway: result.payment_gateway
              },
              () => {
                document.getElementById("process-payment-form").submit();
              }
            );
          } else {
            displayToastMessageForResponse(
              this.props.translate("pro-invoices.title"),
              result
            );
          }
        },
        error => {
          displayToastMessageForResponse(
            this.props.translate("pro-invoices.title"),
            error
          );
        }
      )
      .catch(error => {
        displayToastMessageForResponse(
          this.props.translate("pro-invoices.title"),
          error
        );
      });
  };

  onEditPayer = contact => {
    this.setState({
      ...this.state,
      selectedPayer: {
        ...contact,
        country:
          contact.country && contact.country.id
            ? { label: contact.country.name, value: contact.country.id }
            : contact.country,
        contact_type:
          contact.company === ""
            ? PAYER_DETAILS_TYPE_INDIVIDUAL
            : PAYER_DETAILS_TYPE_COMPANY
      },
      showUpdatePayerModal: true
    });
  };

  onTriggerUpdatePayerFormSubmit = () => {
    document
      .getElementById("proinvoice-payer-details-form")
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  validatePayer = values => {
    //if (Object.keys(values).length === 0) return {};
    //if (Object.keys(values).length === 1) return {}; // don't validate if contact_type is only field
    if (values.contact_type === PAYER_DETAILS_TYPE_COMPANY) {
      return {
        email: validateEmail(values.email),
        first_name: validateFirstName(values.first_name),
        last_name: validateLastName(values.last_name),
        // phone: validateMkPhoneNumber(values.phone),
        country:
          values.country && values.country.id
            ? validateCountry(values.country.id)
            : undefined,
        address: validateAddress(values.address),
        city: validateCity(values.city),
        zip: validateZipCode(values.zip),
        tax_number: validateTaxNumber(values.tax_number),
        company: validateCompany(values.company)
      };
    }
    return {
      email: validateEmail(values.email),
      first_name: validateFirstName(values.first_name),
      last_name: validateLastName(values.last_name),
      // phone: validateMkPhoneNumber(values.phone),
      country:
        values.country && values.country.id
          ? validateCountry(values.country.id)
          : undefined,
      address: validateAddress(values.address),
      city: validateCity(values.city),
      zip: validateZipCode(values.zip)
    };
  };
  onUpdatePayer = values => {
    return this.props
      .assignProInvoicePayer(this.state.proinvoice, {
        ...values,
        phone: normalizeMacedonianPhoneNumber(values.phone),
        country_id: values.country.value
          ? values.country.value
          : values.country.id,
        tax_number:
          values.contact_type === PAYER_DETAILS_TYPE_COMPANY
            ? values.tax_number
            : "",
        company:
          values.contact_type === PAYER_DETAILS_TYPE_COMPANY
            ? values.company
            : ""
      })
      .then(result => {
        const { payer } = result;
        this.setState({
          ...this.state,
          proinvoice: {
            ...this.state.proinvoice,
            invoice_to: {
              ...payer
            }
          }
        });
        this.onHideUpdatePayerModal();
        this.onHideChangePayerModal();
        this.onHideCreatePayerModal();

        displayToastMessageForResponse(
          this.props.translate("pro-invoices.pro-invoice"),
          result
        );
      });
  };

  onSubmittingPayerModal = state => {
    this.setState({
      submittingPayerModal: state.submitting,
      invalidPayer: state.invalid
    });
  };

  onHideUpdatePayerModal = () => {
    this.setState({
      showUpdatePayerModal: false
    });
  };

  onSearchContacts = (value, callback) => {
    this.props.searchPayers(value).then(contacts => {
      this.setState({
        ...this.state,
        contacts: contacts.map(contact => {
          return {
            ...contact,
            contact_type:
              contact.company === ""
                ? PAYER_DETAILS_TYPE_INDIVIDUAL
                : PAYER_DETAILS_TYPE_COMPANY
          };
        })
      });
      const reactSelectCompatibleContacts = contacts.map(contact => {
        const name =
          contact.company !== ""
            ? contact.company
            : `${contact.first_name} ${contact.last_name}`;
        const label =
          name !== "" ? `${name} (${contact.email})` : contact.email;
        return {
          label,
          email: contact.email,
          name: name,
          value: contact.id,
          type:
            contact.company !== ""
              ? PAYER_DETAILS_TYPE_COMPANY
              : PAYER_DETAILS_TYPE_INDIVIDUAL
        };
      });
      callback(reactSelectCompatibleContacts);
    });
  };

  onSelectContact = selected => {
    if (!selected) {
      this.setState({
        ...this.state,
        selectedPayer: null
      });
    } else {
      const selectedContact = this.state.contacts.find(
        contact => contact.id === selected.value
      );
      this.setState({
        ...this.state,
        selectedPayer: {
          ...selectedContact,
          contact_type:
            selectedContact.company === ""
              ? PAYER_DETAILS_TYPE_INDIVIDUAL
              : PAYER_DETAILS_TYPE_COMPANY,
          country_id: selectedContact.country.id
        }
      });
    }
  };

  onCreatePayer = () => {
    this.setState({
      selectedPayer: { contact_type: PAYER_DETAILS_TYPE_INDIVIDUAL },
      showChangePayerModal: false,
      showCreatePayerModal: true
    });
  };

  onHideCreatePayerModal = () => {
    this.setState({
      showCreatePayerModal: false
    });
  };

  onChangePayer = contact => {
    this.setState({
      selectedPayer: { ...contact, country_id: contact.country.id },
      showChangePayerModal: true
    });
  };

  onHideChangePayerModal = () => {
    this.setState({
      showChangePayerModal: false
    });
  };

  onSetPaymentMethodsRef = ref => {
    this.paymentMethodsRef = ref;
  };

  onScrollToPaymentMethods = () => {
    this.setState(
      {
        ...this.state,
        highlightPaymentMethods: true,
        highlightPaymentMethodsTimeout: setTimeout(() => {
          this.setState({
            highlightPaymentMethods: false,
            highlightPaymentMethodsTimeout: clearTimeout(
              this.state.highlightPaymentMethodsTimeout
            )
          });
        }, 5000)
      },
      () => {
        if (this.paymentMethodsRef) {
          try {
            this.paymentMethodsRef.scrollIntoView({ behavior: "smooth" });
          } catch (e) {
            this.paymentMethodsRef.scrollIntoView(false);
          }
        }
      }
    );
  };

  onShowCancelProInvoiceModal = () => {
    this.setState({
      ...this.state,
      showCancelProInvoiceModal: true
    });
  };

  onHideCancelProInvoiceModal = () => {
    this.setState({
      ...this.state,
      showCancelProInvoiceModal: false,
      canceling: false
    });
  };

  onCancelProInvoice = () => {
    this.setState({
      ...this.state,
      canceling: true
    });
    return this.props
      .cancelProInvoice(this.state.proinvoice)
      .then(
        result => {
          const { error, messages, invoice } = result;
          if (!error) {
            this.setState({
              ...this.state,
              canceling: false,
              showCancelProInvoiceModal: false,
              proinvoice: invoice
            });
            displayToastMessageForResponse(
              this.props.translate("pro-invoices.title"),
              { error, messages }
            );
          }
        },
        error => {
          this.onHideCancelProInvoiceModal();
          displayToastMessageForResponse(
            this.props.translate("pro-invoices.title"),
            error
          );
        }
      )
      .catch(error => {
        this.onHideCancelProInvoiceModal();
        displayToastMessageForResponse(
          this.props.translate("pro-invoices.title"),
          error
        );
      });
  };

  componentWillUnmount() {
    if (this.state.highlightPaymentMethodsTimeout) {
      clearTimeout(this.state.highlightPaymentMethodsTimeout);
    }
  }

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              {!this.state.proinvoice && <ContentLoader />}
              {this.state.proinvoice && (
                <Breadcrumb separator={true} className="d-print-none">
                  <BreadcrumbItem
                    to={ROUTE_INDEX}
                    title={translate("breadcrumbs.home")}
                  />
                  <BreadcrumbItem
                    to={ROUTE_BILLING}
                    title={translate("breadcrumbs.pro-invoices")}
                  />
                  <BreadcrumbItem title={`#${this.state.proinvoice.id}`} />
                </Breadcrumb>
              )}

              {this.state.proinvoice && (
                <div className="row">
                  <StyledViewProInvoiceWrapper className="col-12 col-sm-12 col-md-12 col-lg-8 main-print-wrapper">
                    <Container>
                      <StyledViewProInvoice isMobile={isMobileOnly}>
                        <div className="row">
                          <div className="col-12 col-sm-5 col-md-5">
                            <StyledProInviceName>
                              {translate("pro-invoices.view.title")} #
                              {this.state.proinvoice.id}
                            </StyledProInviceName>
                          </div>
                          <div className="col-12 col-sm-7 col-md-7 text-md-right text-sm-center">
                            {isMobileOnly &&
                              this.state.proinvoice &&
                              this.state.proinvoice.status.code ===
                              PAYMENT_STATUS_UNPAID && (
                                <StyledPayNowCallToAction
                                  isMobile={isMobileOnly}
                                  onClick={() =>
                                    this.onScrollToPaymentMethods()
                                  }
                                >
                                  {translate("pro-invoices.view.pay-now")}
                                </StyledPayNowCallToAction>
                              )}
                            <AdaptableButtonGroup
                              className="d-print-none mainDivButtons"
                              title={translate("pro-invoices.view.actions")}
                            >
                              <SecondaryButton onClick={e => this.onPrint()}>
                                {translate("pro-invoices.view.print")}
                              </SecondaryButton>
                              <SecondaryButton
                                disabled={this.state.downloading}
                                submitting={this.state.downloading}
                                loadingIconColor="blue"
                                onClick={e =>
                                  this.onDownload(this.state.proinvoice.id)
                                }
                              >
                                {translate("pro-invoices.view.download-pdf")}
                              </SecondaryButton>
                              <SecondaryButton
                                onClick={e =>
                                  this.onShowForwardProInvoiceModal()
                                }
                              >
                                {translate("pro-invoices.view.email")}
                              </SecondaryButton>
                            </AdaptableButtonGroup>
                            {!isMobileOnly &&
                              this.state.proinvoice &&
                              this.state.proinvoice.status.code ===
                              PAYMENT_STATUS_UNPAID && (
                                <StyledPayNowCallToAction
                                  isMobile={isMobileOnly}
                                  onClick={() =>
                                    this.onScrollToPaymentMethods()
                                  }
                                >
                                  {translate("pro-invoices.view.pay-now")}
                                </StyledPayNowCallToAction>
                              )}
                          </div>
                        </div>
                        <ProInvoicePaymentDetails
                          amountDue={this.state.proinvoice.total}
                          dateCreated={this.state.proinvoice.created_at}
                          dueDate={this.state.proinvoice.due_date}
                          currency={this.state.proinvoice.currency.code}
                          invoiceNumber={this.state.proinvoice.id}
                          status={this.state.proinvoice.status}
                        />

                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6">
                            <StyledInvoiceToWrapper>
                              {this.state.proinvoice.invoice_to && (
                                <InvoiceTo
                                  contact={this.state.proinvoice.invoice_to}
                                  onEditPayer={this.onEditPayer}
                                  onChangePayer={this.onChangePayer}
                                  proInvoiceStatus={
                                    this.state.proinvoice.status.code
                                  }
                                />
                              )}
                            </StyledInvoiceToWrapper>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6">
                            <StyledInvoiceToWrapper>
                              <PayTo />
                            </StyledInvoiceToWrapper>
                          </div>
                        </div>

                        <StyledProInvoiceItemsWrapper className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <ItemsTable items={this.state.proinvoice.items} />
                          </div>
                          <div className="col-12 col-sm-12 col-md-5 offset-md-7">
                            <InvoiceTotal
                              total={parseFloat(this.state.proinvoice.total)}
                              subtotal={parseFloat(
                                this.state.proinvoice.subtotal
                              )}
                              tax={parseFloat(this.state.proinvoice.tax)}
                              tax_rate={parseFloat(
                                this.state.proinvoice.tax_rate
                              )}
                              credit={parseFloat(this.state.proinvoice.credit)}
                              discount={0}
                              currency={this.state.proinvoice.currency.code}
                            />
                          </div>
                        </StyledProInvoiceItemsWrapper>
                        <StyledProInvoiceItemsWrapper
                          className="row"
                          style={{ marginTop: "20px" }}
                        >
                          <div className="col-12 col-sm-12 col-md-12">
                            <TransactionsTable
                              transactions={this.state.proinvoice.transactions}
                              balance={this.state.proinvoice.balance}
                              currency={this.state.proinvoice.currency.code}
                            />
                          </div>
                        </StyledProInvoiceItemsWrapper>
                      </StyledViewProInvoice>
                    </Container>
                  </StyledViewProInvoiceWrapper>
                  <StyledViewProInvoiceWrapper className="col-12 col-sm-12 col-md-12 col-lg-4 d-print-none">
                    <Payment
                      title={translate("pro-invoices.view.payment")}
                      invoice={this.state.proinvoice}
                      availableCredit={this.props.credit}
                      isFetchingCredit={this.props.isFetchingCredit}
                      onApplyCredit={this.onApplyCreditSubmit}
                      onValidateCredit={this.validateApplyCredit}
                      disableCreditForm={
                        this.state.proinvoice.notes ===
                        PROINVOICE_TYPE_CREDIT_PURCHASE ||
                        this.props.credit.amount === 0
                      }
                      selectedPayment={this.state.selectedPayment}
                      onSelectPayment={this.onSelectPayment}
                      paymentMethods={this.state.paymentMethods}
                      onPayProInvoice={this.onPayProInvoice}
                      processPaymentGateway={this.state.processPaymentGateway}
                      paymentFailed={this.state.paymentFailed}
                      submittingProInvoicePayment={
                        this.state.submittingProInvoicePayment
                      }
                      applyCreditSubmitting={this.state.applyCreditSubmitting}
                      highlightPaymentMethods={
                        this.state.highlightPaymentMethods
                      }
                      onSetPaymentMethodsRef={this.onSetPaymentMethodsRef}
                      onCancelProInvoice={this.onShowCancelProInvoiceModal}
                      cancelingProInvoice={this.state.canceling}
                    />
                  </StyledViewProInvoiceWrapper>
                </div>
              )}

              {this.state.showForwardModal && (
                <Modal
                  title={translate("pro-invoices.view.send-via-email")}
                  onCloseModal={this.onHideForwardProInvoiceModal}
                  body={() => (
                    <ForwardDialog
                      onSubmit={this.onForwardProInvoice}
                      onSubmitting={this.onSubmittingForwardProInvoice}
                      validate={this.onValidateForwardProInvoice}
                    />
                  )}
                  footer={() => [
                    <SecondaryButton
                      disabled={this.state.submittingForwardProInvoice}
                      onClick={e => this.onHideForwardProInvoiceModal()}
                      key="forward-proinvoice-cancel"
                    >
                      {translate("pro-invoices.view.cancel")}
                    </SecondaryButton>,
                    <PrimaryButton
                      disabled={this.state.submittingForwardProInvoice}
                      submitting={this.state.submittingForwardProInvoice}
                      onClick={e => this.onSubmitForwardProInvoice()}
                      type="submit"
                      key="forward-proinvoice-send"
                    >
                      {translate("pro-invoices.view.send")}
                    </PrimaryButton>
                  ]}
                />
              )}
            </div>

            {this.state.showUpdatePayerModal && (
              <Modal
                title={translate("pro-invoices.view.update-payer-info")}
                subTitle={`${translate("pro-invoices.view.title")}: ${this.state.proinvoice ? this.state.proinvoice.id : 0
                  }`}
                onCloseModal={this.onHideUpdatePayerModal}
                body={() => (
                  <PayerDetailsForm
                    contact={this.state.selectedPayer}
                    onSubmit={this.onUpdatePayer}
                    onSubmitting={this.onSubmittingPayerModal}
                    validate={this.validatePayer}
                    disabledFields={{ email: true }}
                    countries={this.props.countries}
                  />
                )}
                footer={() => (
                  <Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideUpdatePayerModal()}
                    >
                      {translate("pro-invoices.view.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={
                        this.state.submittingPayerModal ||
                        this.state.invalidPayer
                      }
                      submitting={this.state.submittingPayerModal}
                      onClick={() => this.onTriggerUpdatePayerFormSubmit()}
                    >
                      {translate("pro-invoices.view.save-changes")}
                    </PrimaryButton>
                  </Fragment>
                )}
              />
            )}

            {this.state.showCreatePayerModal && (
              <Modal
                title={translate("pro-invoices.view.create-contact")}
                subTitle={`${translate("pro-invoices.view.title")}: ${this.state.proinvoice ? this.state.proinvoice.id : 0
                  }`}
                onCloseModal={this.onHideCreatePayerModal}
                body={() => (
                  <PayerDetailsForm
                    onSubmit={this.onUpdatePayer}
                    onCancel={this.onHideCreatePayerModal}
                    onSubmitting={this.onSubmittingPayerModal}
                    validate={this.validatePayer}
                    showPayerNotice={true}
                    countries={this.props.countries}
                  />
                )}
                footer={() => (
                  <Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideCreatePayerModal()}
                    >
                      {translate("pro-invoices.view.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={
                        this.state.submittingPayerModal ||
                        this.state.invalidPayer
                      }
                      submitting={this.state.submittingPayerModal}
                      onClick={() => this.onTriggerUpdatePayerFormSubmit()}
                    >
                      {translate(
                        "pro-invoices.view.create-and-assign-as-payer"
                      )}
                    </PrimaryButton>
                  </Fragment>
                )}
              />
            )}

            {this.state.showChangePayerModal && (
              <Modal
                title={translate("pro-invoices.view.change-invoice-payer")}
                subTitle={`${translate("pro-invoices.view.title")}: ${this.state.proinvoice ? this.state.proinvoice.id : 0
                  }`}
                onCloseModal={this.onHideChangePayerModal}
                body={() => (
                  <PayerDetailsFormWithSearch
                    onSubmit={this.onUpdatePayer}
                    onSubmitting={this.onSubmittingPayerModal}
                    onCancel={this.onHideChangePayerModal}
                    onSearchContacts={this.onSearchContacts}
                    onSelectContact={this.onSelectContact}
                    contact={this.state.selectedPayer}
                    onCreateContact={this.onCreatePayer}
                    countries={this.props.countries}
                    validate={this.validatePayer}
                    showPayerNotice={true}
                  />
                )}
                footer={() => (
                  <Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideChangePayerModal()}
                    >
                      {translate("pro-invoices.view.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={
                        this.state.submittingPayerModal ||
                        this.state.invalidPayer
                      }
                      submitting={this.state.submittingPayerModal}
                      onClick={() => this.onTriggerUpdatePayerFormSubmit()}
                    >
                      {translate("pro-invoices.view.assign-as-payer")}
                    </PrimaryButton>
                  </Fragment>
                )}
              />
            )}

            {this.state.showCancelProInvoiceModal && (
              <ActionModal
                title={translate("pro-invoices.view.cancel-proinvoice-tooltip")}
                onCloseActionModal={this.onHideCancelProInvoiceModal}
                body={() => (
                  <div>
                    <p>
                      {translate(
                        "pro-invoices.view.cancel-proinvoice-warning-1"
                      )}
                    </p>
                    <p>
                      {translate(
                        "pro-invoices.view.cancel-proinvoice-warning-2"
                      )}
                    </p>
                    <NoticeDanger>
                      {translate(
                        "pro-invoices.view.cancel-proinvoice-warning-notice"
                      )}
                    </NoticeDanger>
                  </div>
                )}
                footer={() => (
                  <Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideCancelProInvoiceModal()}
                    >
                      {translate("pro-invoices.view.cancel")}
                    </SecondaryButton>
                    <PrimaryWarningButton
                      disabled={this.state.canceling}
                      submitting={this.state.canceling}
                      onClick={() => this.onCancelProInvoice()}
                    >
                      {translate("pro-invoices.view.cancel-proinvoice-tooltip")}
                    </PrimaryWarningButton>
                  </Fragment>
                )}
              />
            )}
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    paymentMethods: getPaymentMethods(state),
    credit: getAvailableCredit(state),
    isFetchingCredit: isFetchingCredit(state),
    countries: getCountriesFormatted(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProInvoice,
      downloadProInvoice,
      applyCreditToProInvoice,
      forwardProInvoice,
      fetchPaymentMethods,
      payProInvoice,
      updateProInvoicePayment,
      assignProInvoicePayer,
      searchPayers,
      fetchCredit,
      reduceCredit,
      cancelProInvoice
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(ViewProInvoice)));
