import { parse } from "tldts";

const parseDomain = domain => {
    const result = parse(domain);

    return {
        input: domain,
        tld: result.publicSuffix || "",
        sld: result.domainWithoutSuffix || "",
        domain: result.domain || "",
        subdomain: result.subdomain || ""
    };
};

export default parseDomain;
